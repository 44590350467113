<template>
  <div class="arhive-request">
    <transition name="fade" mode="out-in">
      <base-loader v-if="isLoading" class="arhive-request__loader" />
      <page-block v-else :title="$t('title')" class="arhive-request__block">
        <div class="l-col">
          <label class="standart-title l-col">{{ $t('incident') }}</label>
          <InputText v-model="search" type="text" class="p-inputtext-sm" />
          <!--          @input="isActive = true"-->
          <!--          @blur="isActive = true"-->
          <!--          <Button icon="pi pi-search" class="p-button-blue" @click="setFilter(search)" />-->
          <plain-button color="primary" class="ml" nowrap @click="all()">
            {{ $t('all') }}
          </plain-button>
        </div>
        <transition name="fade" mode="out-in">
          <base-table
            v-if="isReady"
            :columns="tableHead"
            :list="paginList"
            class="arhive-request__table"
          >
            <!--            @sort-change="onSortChange"-->
            <base-table-row
              v-for="(item, i) in paginList"
              :key="i"
              :columns="tableHead"
              :item="item"
              col-class="arhive-request__table-item__col"
              class="arhive-request__table"
            >
              <template #id>
                <router-link
                  :to="{ name: 'ArchivTicket', params: { id: item.id } }"
                  class="arhive-request__table-item__name standart-text"
                >
                  {{ item.id }}
                </router-link>
              </template>
              <template #report>
                <span :class="classes">
                  {{ item.reportid }}
                </span>
              </template>
              <template #type>
                <span class="arhive-request__item-realname standart-text">
                  {{ item.dtype }}
                </span>
              </template>
              <template #date>
                <span class="arhive-request__item-realname standart-text">
                  {{ item.date }}
                </span>
              </template>
              <template #format>
                <span class="arhive-request__item-realname standart-text">
                  {{ item.format }}
                </span>
              </template>
              <template #download>
                <span class="arhive-request__item-link standart-text">
                  <a :href="getLink(item.item)" class="arhive-request__item-link standart-title">{{
                    $t('tableHead.download')
                  }}</a>
                </span>
              </template>
              <template #status>
                <main-label :color="'success'" theme="plain"> {{ $t('arhiv') }} </main-label>
              </template>
            </base-table-row>
          </base-table>
        </transition>
        <new-pagination
          :step="pagin.step"
          :start="pagin.start || 0"
          :count="filtredIncedents.length"
          type="base"
          @set-step="setStep"
          @new-pagination="changePagination"
        />
      </page-block>
    </transition>
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable';
import BaseTableRow from '@/components/Table/BaseTableRow';
import notifications from '@/layouts/Support/mixins/notifications';
import mixin from '@/layouts/Profile/mixins';
import showErrorModal from '@/mixins/showErrorModal';
import MainLabel from '@/components/Labels/MainLabel.vue';
import InputText from 'primevue/inputtext';
import NewPagination from '@/components/Pagination/NewPagination';
import pagination from '@/mixins/pagination';

export default {
  name: 'Incidents',
  components: {
    BaseTable,
    InputText,
    BaseTableRow,
    MainLabel,
    NewPagination,
  },
  mixins: [notifications, mixin, showErrorModal, pagination],
  data() {
    return {
      search: '',
      isActive: false,
      isLoading: true,
      tableHead: [
        {
          key: 'report',
          label: 'report',
          style: {
            width: '80px',
            maxWidth: '100px',
          },
        },
        {
          key: 'date',
          label: this.$t('tableHead.date'),
          style: {
            width: '100px',
            maxWidth: '120px',
          },
        },
        {
          key: 'type',
          label: this.$t('tableHead.type'),
          style: {
            width: '104px',
          },
        },
        {
          key: 'format',
          label: this.$t('tableHead.format'),
          style: {
            width: '104px',
          },
        },
        {
          key: 'download',
          label: this.$t('tableHead.download'),
          style: {
            width: '104px',
          },
        },
      ],
      settingsModal: null,
      rightsModal: null,
    };
  },
  computed: {
    allIncedents() {
      return this.$store.getters['moduleSupport/GET_ALL_INCIDENT'];
    },

    filtredIncedents() {
      if (this.$route.query.value) {
        return this.allIncedents.filter(x => x.reportid.includes(this.$route.query.value));
      } else return this.allIncedents;
    },
    isReady() {
      return this.allIncedents && this.allIncedents.length;
    },
    paginList() {
      const { start, step } = this.pagin;
      const end = start + step;
      return this.filtredIncedents.slice(start, end);
    },
    classes() {
      return !!this.search
        ? 'arhive-request__table-item__name search standart-text'
        : 'arhive-request__table-item__name standart-text';
    },
  },
  watch: {
    '$route.query.value': {
      handler(val) {
        this.search = val;
      },
    },
    search: {
      handler(val) {
        this.setFilter(val);
      },
      deep: true,
    },
  },
  beforeMount() {
    this.search = '';
  },
  mounted() {
    this.search = '';
    this.setFilter(this.$route.query.value);
    if (this.isReady) this.isLoading = false;
    else if (!this.loading && !this.isReady) this.fetchAllIncedent();
  },

  methods: {
    setFilter(val) {
      this.search = val;
      this.$router
        .push({
          name: 'Incidents',
          query: { filter_by: 'report', value: val },
        })
        .catch(() => {});
    },
    all() {
      this.setFilter('');
    },
    async fetchAllIncedent() {
      this.isLoading = true;
      return this.$store
        .dispatch('moduleSupport/fetchAllIncedent')
        .finally(() => (this.isLoading = false));
    },
    getLink(item) {
      return item.length > 4 ? item.split('"')[3] : '';
    },
  },
};
</script>
<i18n>
{
  "ru": {
    "title": "Текущие инциденты",
    "arhiv": "Вопрос решен",
    "all": "Показать все",
    "incident": "Инцидент: ",
    "tableHead": {
      "report": "Репорт",
      "date": "Дата",
      "type": "Тип",
      "format": "Формат",
      "download": "Скачать"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.search {
  background-color: $color-green.light;
  //padding: 0.5rem 0;
  padding: 0.5rem;
  border-radius: 10px;

}
.arhive-request {
  &__table {
    margin-bottom: 1.25rem;
    //background-color: #4cbf86
  }

  &__after {
    +breakpoint(sm-and-up) {
      flexy(flex-start, flex-start);
    }

    &-text {
      margin-bottom: 1.5rem;

      +breakpoint(sm-and-up) {
        margin-right: 1.5rem;
        margin-bottom: 0;
      }
    }
  }

  &__empty {
    max-width: 50rem;
  }

  &__add {
    width: 100%;
    +breakpoint(sm-and-up) {
      width: auto;
      flex: 0 0 auto;
    }
  }

  >>> &__item {
    vertical-align: middle;

    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset !important;
    }

    +breakpoint(ml-and-up) {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }
    &-link {
      text-decoration: none;
      color: $primary-color;

      &:hover {
        color: darken($primary-color, 20%);
      }
    }

    &:hover {
      //background: var(--main-card-bg);
      text-overflow: unset;
      overflow: hidden;
      z-index: 2;
    }
  }

  &__item {
    &-name,
    &-realname,
    &-email {
      white-space: nowrap;
      text-overflow: ellipsis;
      position: relative;
      padding: 0.75rem;
      margin: -0.75rem;
      box-sizing: content-box;
      max-width: 100%;


      &::after {
        +breakpoint(ml-and-up) {
          content: '';
          display: block;
          size(2.5em, 100%);
          absolute(0px, 100%);
          position absolute;
          top: 0;
          left: calc(100% - 0.75rem);
          background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
          opacity: 0;
        }
      }

      &:hover::after {
        +breakpoint(ml-and-up) {
          opacity: 1;
        }
      }
    }
  }
}
  .ml{
    margin-left: 1rem;
  }
.arhive-request__table-item {
  font-size: 1rem;
  &__search {
    background-color: $color-green.light
    background: $color-green.light
  }
  >>> &__col {
    vertical-align: middle;

    +breakpoint(xs-and-up) {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }
    &-link {
      position: relative;
      padding: 0.5em;
      margin: -0.5em;
      color: $primary-color;
      text-decoration: none;
      white-space: nowrap;
      background: inherit;
      &:hover {
        color: darken($primary-color, 20%);
      }

      &:hover::after {
        +breakpoint(ml-and-up) {
          content: '';
          display: block;
          size(2.5em, 100%);
          absolute(0px, 100%);
          background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
        }
      }
    }

    &:hover {
      //background: var(--main-card-bg);
      text-overflow: unset;
      z-index: 2;
    }
  }
 &__link {
    position: relative;
    padding: 0.5em;
    margin: -0.5em;
    color: $primary-color;
    text-decoration: none;
    white-space: nowrap;
    background: inherit;
    &:hover {
      color: darken($primary-color, 20%);
    }

    &:hover::after {
      +breakpoint(ml-and-up) {
        content: '';
        display: block;
        size(2.5em, 100%);
        absolute(0px, 100%);
        background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
      }
    }
  }
}
</style>
